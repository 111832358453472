/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICurationListState, ICurationsRequestPayload } from 'store/types';
import { ICuration } from '@types';

const initialState: ICurationListState = {
  main: {
    paging: { pageSize: 30, page: 1 },
    data: undefined,
    error: null,
  },
  product: {
    paging: { pageSize: 30, page: 1 },
    data: undefined,
    error: null,
  },
};

export const curationListSlice = createSlice({
  name: 'curationList',
  initialState,
  reducers: {
    getCurationsRequest: (
      state,
      action: PayloadAction<ICurationsRequestPayload>,
    ) => {},
    getCurationsSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState;
        data: {
          status: 'fail' | 'success';
          curations: ICuration[];
        };
      }>,
    ) => {
      const {
        key,
        data: { curations },
      } = action.payload;
      state[key]!.data = curations;
    },
    getCurationsFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string;
      }>,
    ) => {
      const { key, error } = action.payload;
      state[key as keyof typeof initialState]!.data = [];
      state[key as keyof typeof initialState]!.error = error;
    },
  },
});

export const { getCurationsRequest, getCurationsSuccess, getCurationsFailure } =
  curationListSlice.actions;

export default curationListSlice.reducer;
