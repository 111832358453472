import { all, takeLatest } from 'redux-saga/effects';
import {
  searchGeoRequest,
  searchGeoSuccess,
  searchGeoFailure,
  getAllCampMapPinsRequest,
  getAllCampMapPinsSuccess,
  getAllCampMapPinsFailure,
  getFilteredCampsListInGeoRequest,
  getFilteredCampsListInGeoSuccess,
  getFilteredCampsListInGeoFailure,
  getCampInGeoRequest,
  getCampInGeoSuccess,
  getCampInGeoFailure,
} from 'store/reducers/searchGeo';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  getFilteredCampList,
  searchGeo,
  getAllCampMapPin,
  getCampInGeo,
} from 'api';
import { toast } from 'react-toastify';

export default function* searchGeoCampSaga() {
  yield all([
    takeLatest(
      searchGeoRequest.type,
      createFetchAction(
        searchGeo,
        searchGeoSuccess,
        searchGeoFailure,
        function* success(data: any, payload: any) {
          if (data.camps.length === 0)
            yield toast.success('검색결과가 없습니다 다시 검색해주세요!');
        },
      ),
    ),
    takeLatest(
      getFilteredCampsListInGeoRequest.type,
      createFetchAction(
        getFilteredCampList,
        getFilteredCampsListInGeoSuccess,
        getFilteredCampsListInGeoFailure,
      ),
    ),
    takeLatest(
      getCampInGeoRequest.type,
      createFetchAction(getCampInGeo, getCampInGeoSuccess, getCampInGeoFailure),
    ),
    takeLatest(
      getAllCampMapPinsRequest.type,
      createFetchAction(
        getAllCampMapPin,
        getAllCampMapPinsSuccess,
        getAllCampMapPinsFailure,
      ),
    ),
  ]);
}
