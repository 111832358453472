import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IZone } from '@types';

export type BookingType = 'normal' | 'longStay';

interface InitalState {
  /**
   * 일반예약 or 장박
   */
  bookingType: BookingType;
  zones: IZone[];
  /** 장박 시작일 */
  startDate: Date | null;
  /** 장박 종료일 */
  endDate: Date | null;

  prevStartDate: Date | null;
  prevEndDate: Date | null;

  adultCnt: number;
  teenCnt: number;
  childCnt: number;

  needToShowToast: boolean;
}

const initialState: InitalState = {
  bookingType: 'normal',

  zones: [],

  startDate: null,
  endDate: null,

  prevStartDate: null,
  prevEndDate: null,

  adultCnt: 2,
  teenCnt: 0,
  childCnt: 0,

  needToShowToast: false,
};

export const longStaySlice = createSlice({
  name: 'longStay',
  initialState,
  reducers: {
    setBookingType: (state, action: PayloadAction<BookingType>) => {
      state.bookingType = action.payload;
    },
    setLongStayPeriod: (
      state,
      action: PayloadAction<{ startDate: Date | null; endDate: Date | null }>,
    ) => {
      const { startDate, endDate } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
    },

    setPrevLongStayPeriod: (
      state,
      action: PayloadAction<{ startDate: Date | null; endDate: Date | null }>,
    ) => {
      const { startDate, endDate } = action.payload;
      state.prevStartDate = startDate;
      state.prevEndDate = endDate;
    },

    setAdultCnt: (state, action: PayloadAction<number>) => {
      state.adultCnt = action.payload;
    },
    setTeenCnt: (state, action: PayloadAction<number>) => {
      state.teenCnt = action.payload;
    },
    setChildCnt: (state, action: PayloadAction<number>) => {
      state.childCnt = action.payload;
    },

    setLongTermReservationState: (state, action: PayloadAction<any>) => {
      state.adultCnt = action.payload.adultCnt;
      state.teenCnt = action.payload.teenCnt;
      state.childCnt = action.payload.childCnt;
      state.bookingType = action.payload.bookingType;
      state.startDate = new Date(action.payload.startDate);
      state.endDate = new Date(action.payload.endDate);
    },

    setNeedToShowToast: (state, action: PayloadAction<boolean>) => {
      state.needToShowToast = action.payload;
    },

    resetLongTermPeopleCnt: state => {
      state.adultCnt = 2;
      state.teenCnt = 0;
      state.childCnt = 0;
    },
  },
});

export const {
  setBookingType,
  setLongStayPeriod,
  setPrevLongStayPeriod,
  setAdultCnt,
  setTeenCnt,
  setChildCnt,
  setLongTermReservationState,
  setNeedToShowToast,
  resetLongTermPeopleCnt,
} = longStaySlice.actions;

export default longStaySlice.reducer;
