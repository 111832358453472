import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICamp,
  ICampExhibition,
  ICampExhibitionCode,
  TCampExhibitionType,
} from '@types';
import {
  ICampExhibitionData,
  IErrorPayloadWithKey,
  TCampExhibitionState,
} from 'store/types';

const initialBasicState: ICampExhibitionData = {
  data: {
    exhibitionCodeList: [],
    targetExhibitionInfo: {
      exhibitionInfo: {} as ICampExhibition,
      campList: [] as ICamp[],
      offset: 0,
      hasMore: false,
      locations: [],
    },
  },
  isTouring: false,
  error: null,
};

const initialState: TCampExhibitionState = {
  main: initialBasicState,
  sub: initialBasicState,
  additional: initialBasicState,
  recommend: initialBasicState,
};

export const campExhibition = createSlice({
  name: 'campExhibition',
  initialState,
  reducers: {
    // 기획전 코드 목록 불러오기
    getCampExhibitionCodeListRequest: (
      state,
      action: PayloadAction<{ key: TCampExhibitionType }>,
    ) => {
      const { key } = action.payload;

      state[key] = initialBasicState;
    },
    getCampExhibitionCodeListSuccess: (
      state,
      action: PayloadAction<{
        key: TCampExhibitionType;
        data: {
          data: ICampExhibitionCode[];
        };
      }>,
    ) => {
      const { key, data } = action.payload;
      const { data: exhibitionCodeList } = data;

      state[key].data.exhibitionCodeList = exhibitionCodeList;
    },
    getCampExhibitionCodeListFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;

      state[key as TCampExhibitionType] = { ...initialBasicState, error };
      state[key as TCampExhibitionType].error = error;
    },

    // 기획전 정보 불러오기'
    getCampExhibitionInfoRequest: (
      state,
      action: PayloadAction<{
        key: TCampExhibitionType;
        exhibitionCode: string;
      }>,
    ) => {
      const { key } = action.payload;

      state[key].data.targetExhibitionInfo = {
        exhibitionInfo: {} as ICampExhibition,
        campList: [] as ICamp[],
        offset: 0,
        hasMore: true,
        locations: [],
      };
      state[key].error = null;
    },
    getCampExhibitionInfoSuccess: (
      state,
      action: PayloadAction<{
        key: TCampExhibitionType;
        data: ICampExhibition;
      }>,
    ) => {
      const { key, data: exhibitionInfo } = action.payload;
      state[key].data.targetExhibitionInfo.exhibitionInfo = exhibitionInfo;
      state[key].error = null;
    },
    getCampExhibitionInfoFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;

      state[key as TCampExhibitionType].data.targetExhibitionInfo = {
        exhibitionInfo: {} as ICampExhibition,
        campList: [] as ICamp[],
        offset: 0,
        hasMore: true,
        locations: [],
      };
      state[key as TCampExhibitionType].error = error;
    },

    // 해당 기획전 캠핑장 목록 불러오기

    getCampExhibitionCampListRequest: (
      state,
      action: PayloadAction<{
        key: TCampExhibitionType;
        skip: number;
        limit: number;
        exhibitionCode: string;
      }>,
    ) => {
      const { key } = action.payload;

      state[key].data.targetExhibitionInfo.campList = [];
      state[key].data.targetExhibitionInfo.offset = 0;
      state[key].data.targetExhibitionInfo.hasMore = true;
      state[key].error = null;
    },
    getMoreCampExhibitionCampListRequest: (
      state,
      action: PayloadAction<{
        key: TCampExhibitionType;
        skip: number;
        limit: number;
        exhibitionCode: string;
      }>,
    ) => {
      const { key, skip } = action.payload;
      state[key].data.targetExhibitionInfo.offset = skip;
      state[key].error = null;
    },
    getCampExhibitionCampListSuccess: (
      state,
      action: PayloadAction<{
        key: TCampExhibitionType;
        data: ICamp[];
      }>,
    ) => {
      const { key, data: campList } = action.payload;
      state[key].data.targetExhibitionInfo.campList.push(...campList);
      state[key].data.targetExhibitionInfo.hasMore = campList.length === 10;
      state[key].error = null;
    },
    getCampExhibitionCampListFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;

      state[key as TCampExhibitionType].data.targetExhibitionInfo.campList = [];
      state[key as TCampExhibitionType].data.targetExhibitionInfo.offset = 0;
      state[key as TCampExhibitionType].data.targetExhibitionInfo.hasMore =
        false;
      state[key as TCampExhibitionType].error = error;
    },

    onTouring: (state, action: PayloadAction<{ key: TCampExhibitionType }>) => {
      const { key } = action.payload;

      state[key].isTouring = true;
    },

    offTouring: (
      state,
      action: PayloadAction<{ key: TCampExhibitionType }>,
    ) => {
      const { key } = action.payload;

      state[key].isTouring = false;
    },
  },
});

export const {
  getCampExhibitionCodeListRequest,
  getCampExhibitionCodeListSuccess,
  getCampExhibitionCodeListFailure,

  getCampExhibitionInfoRequest,
  getCampExhibitionInfoSuccess,
  getCampExhibitionInfoFailure,

  getCampExhibitionCampListRequest,
  getMoreCampExhibitionCampListRequest,
  getCampExhibitionCampListSuccess,
  getCampExhibitionCampListFailure,

  onTouring,
  offTouring,
} = campExhibition.actions;

export default campExhibition.reducer;
