import useLazyLoading from 'hooks/useLazyLoading';
import { memo, useEffect, useRef, useState } from 'react';
import LazyImage from 'widgets/New/LazyImage';

interface Props {
  alt: string;
  src: string;
  className?: string;
  onLoad?: () => void;
  placeholderImage?: string;
  backgroundColor?: string;
  animation?: 'fadeIn' | 'blur';
  isLoaded?: boolean;
  objectFit?: string;
  width?: string;
}

const NewLazyImage = ({
  alt,
  src,
  className,
  onLoad,
  placeholderImage,
  backgroundColor,
  animation,
  isLoaded,
  objectFit = 'cover',
  width,
}: Props) => {
  const imgTarget = useRef<HTMLImageElement>(null);

  const onLoadImage = () => {
    if (!imgTarget.current) return;
    imgTarget.current.src = src;
    imgTarget.current.onload = () => {
      animation && imgTarget.current?.classList.add(animation);
      onLoad && onLoad();
    };
  };

  const { onLazyLoading } = useLazyLoading(placeholderImage, onLoadImage);

  useEffect(() => {
    if (!imgTarget.current || isLoaded) return;
    onLazyLoading(imgTarget);
  }, [imgTarget.current, isLoaded]);

  useEffect(() => {
    if (!isLoaded && imgTarget.current) {
      imgTarget.current.onerror = () => {
        if (imgTarget.current && placeholderImage)
          imgTarget.current.src = placeholderImage;
      };
    }
  }, []);

  return (
    <LazyImage
      ref={imgTarget}
      src={isLoaded ? src : placeholderImage}
      className={className}
      alt={alt}
      backgroundColor={backgroundColor}
      objectFit={objectFit}
      width={width}
    />
  );
};

export default memo(NewLazyImage);
