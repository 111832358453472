import styled from 'styled-components';

const LazyImage = styled.img<{
  duration?: number;
  backgroundColor?: string;
  objectFit?: string;
  width?: string;
  height?: string;
}>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  object-fit: ${({ objectFit }) => objectFit};
  background: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.newColors.lightBackground1};

  &.fadeIn {
    animation: fadeIn ${({ duration }) => (duration ? `${duration}s` : '0.8s')};
  }

  &.blur {
    animation: blur ${({ duration }) => (duration ? `${duration}s` : '0.4s')};
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes blur {
    from {
      filter: blur(10px);
      -webkit-filter: blur(10px);
    }
    to {
      filter: none;
      -webkit-filter: none;
    }
  }
`;

export default LazyImage;
