import { checkIsSecretExhibition } from 'api';
import {
  getShopbyItemExhibitionDetail,
  getShopbyItemExhibitionList,
  getShopbyPrivagteExhibitionDetails,
} from 'api/shopby';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import {
  getShopbyMainTagExhibitionDetailFailure,
  getShopbyMainTagExhibitionDetailRequest,
  getShopbyMainTagExhibitionDetailSuccess,
  getShopbyMainTagExhibitionFailure,
  getShopbyMainTagExhibitionRequest,
  getShopbyMainTagExhibitionSuccess,
  getShopbyItemExhibitionDetailFailure,
  getShopbyItemExhibitionDetailRequest,
  getShopbyItemExhibitionDetailSuccess,
  getShopbyItemExhibitionFailure,
  getShopbyItemExhibitionRequest,
  getShopbyItemExhibitionSuccess,
  getShopbyPrivateExhibitionDetailFailure,
  getShopbyPrivateExhibitionDetailRequest,
  getShopbyPrivateExhibitionDetailSuccess,
  getShopbyPrivateExhibitionFailure,
  getShopbyPrivateExhibitionRequest,
  getShopbyPrivateExhibitionSuccess,
  getShopbySpecialPriceExhibitionDetailFailure,
  getShopbySpecialPriceExhibitionDetailRequest,
  getShopbySpecialPriceExhibitionDetailSuccess,
  getShopbySpecialPriceExhibitionFailure,
  getShopbySpecialPriceExhibitionRequest,
  getShopbySpecialPriceExhibitionSuccess,
  checkIsExhibitionVisibleRequest,
  checkIsExhibitionVisibleSuccess,
  checkIsExhibitionVisibleFailure,
} from 'store/reducers/shopbyItemExhibition';

import {
  createFetchAction,
  createShopbyFetchAction,
  createShopbyFetchActionWithoutRequestPayload,
} from 'store/sagas/createFetchAction';
import { IShopbyExhibitionDetailRequestPayload } from 'store/types';

function* getShopbyItemExhibitionListSaga() {
  yield takeLatest(
    getShopbyItemExhibitionRequest.type,
    createShopbyFetchAction(
      getShopbyItemExhibitionList,
      getShopbyItemExhibitionSuccess,
      getShopbyItemExhibitionFailure,
    ),
  );
}

function* getShopbyItemExhibitionDetailSaga() {
  yield takeLatest(
    getShopbyItemExhibitionDetailRequest.type,
    createShopbyFetchAction(
      getShopbyItemExhibitionDetail,
      getShopbyItemExhibitionDetailSuccess,
      getShopbyItemExhibitionDetailFailure,
      undefined,
      function* onSuccess(
        data: any,
        payload: IShopbyExhibitionDetailRequestPayload,
      ) {
        const isSecretExhibition = data.tag?.includes('secret');
        if (isSecretExhibition) {
          yield put(checkIsExhibitionVisibleRequest(data.eventNo));
        }
      },
    ),
  );
}

// 샵바이 숨김 기획전 가져오기.
function* getShopbyPrivateExhibitionListSaga() {
  yield takeEvery(
    getShopbyPrivateExhibitionRequest.type,
    createShopbyFetchActionWithoutRequestPayload(
      getShopbyItemExhibitionList,
      getShopbyPrivateExhibitionSuccess,
      getShopbyPrivateExhibitionFailure,
    ),
  );
}

// 샵바이 숨김 기획전 상세 가져오기.
function* getShopbyPrivateExhibitionDetailSaga() {
  yield takeEvery(
    getShopbyPrivateExhibitionDetailRequest.type,
    createShopbyFetchActionWithoutRequestPayload(
      getShopbyPrivagteExhibitionDetails,
      getShopbyPrivateExhibitionDetailSuccess,
      getShopbyPrivateExhibitionDetailFailure,
    ),
  );
}

// 샵바이 타임특가 기획전 eventNo 요청.
function* getShopbySpecialPriceExhibitionSaga() {
  yield takeEvery(
    getShopbySpecialPriceExhibitionRequest.type,
    createShopbyFetchActionWithoutRequestPayload(
      getShopbyItemExhibitionList,
      getShopbySpecialPriceExhibitionSuccess,
      getShopbySpecialPriceExhibitionFailure,
    ),
  );
}

// 샵바이 타임특기 기획전 상세 요청.
function* getShopbySpecialPriceExhibitionDetailSaga() {
  yield takeEvery(
    getShopbySpecialPriceExhibitionDetailRequest.type,
    createShopbyFetchActionWithoutRequestPayload(
      getShopbyItemExhibitionDetail,
      getShopbySpecialPriceExhibitionDetailSuccess,
      getShopbySpecialPriceExhibitionDetailFailure,
    ),
  );
}

// 샵바이 main tag 기획전 요청
function* getShopbyMainTagExhibitionListSaga() {
  yield takeEvery(
    getShopbyMainTagExhibitionRequest.type,
    createShopbyFetchActionWithoutRequestPayload(
      getShopbyItemExhibitionList,
      getShopbyMainTagExhibitionSuccess,
      getShopbyMainTagExhibitionFailure,
    ),
  );
}

// 샵바이 main tag 기획전 상세 요청
function* getShopbyMainTagExhibitionDetailSaga() {
  yield takeEvery(
    getShopbyMainTagExhibitionDetailRequest.type,
    createShopbyFetchActionWithoutRequestPayload(
      getShopbyPrivagteExhibitionDetails,
      getShopbyMainTagExhibitionDetailSuccess,
      getShopbyMainTagExhibitionDetailFailure,
    ),
  );
}

// 샵바이 기획전 번호로 기획전 금액 노출여부 조회
function* checkIsExhibitionVisibleSaga() {
  yield takeLatest(
    checkIsExhibitionVisibleRequest.type,
    createFetchAction(
      checkIsSecretExhibition,
      checkIsExhibitionVisibleSuccess,
      checkIsExhibitionVisibleFailure,
    ),
  );
}

export default function* shopbyItemExhibitionSaga() {
  yield all([
    getShopbyItemExhibitionListSaga(),
    getShopbyItemExhibitionDetailSaga(),
    getShopbyPrivateExhibitionListSaga(),
    getShopbyPrivateExhibitionDetailSaga(),
    getShopbySpecialPriceExhibitionSaga(),
    getShopbySpecialPriceExhibitionDetailSaga(),
    getShopbyMainTagExhibitionListSaga(),
    getShopbyMainTagExhibitionDetailSaga(),
    checkIsExhibitionVisibleSaga(),
  ]);
}
