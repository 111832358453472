import { subDays } from 'date-fns';

interface Props {
  startDate: Date;
  endDate: Date;
}

const makePeriodChangeToastMessage = ({ startDate, endDate }: Props) => {
  const previousDay = subDays(endDate, 1);

  const start = `${`${startDate.getMonth() + 1}`.padStart(
    2,
    '0',
  )}.${`${startDate.getDate()}`.padStart(2, '0')}`;

  const end = `${`${previousDay.getMonth() + 1}`.padStart(
    2,
    '0',
  )}.${`${previousDay.getDate()}`.padStart(2, '0')}`;

  return `${start}~${end} 로 일정이 변경되었어요.`;
};

export const makePeriodChangeToastMessageForCamp = ({
  startDate,
  endDate,
}: Props) => {
  if (!startDate || !endDate) return '일정이 변경되었어요.';

  const start = `${`${startDate.getMonth() + 1}`.padStart(
    2,
    '0',
  )}.${`${startDate.getDate()}`.padStart(2, '0')}`;

  const end = `${`${endDate.getMonth() + 1}`.padStart(
    2,
    '0',
  )}.${`${endDate.getDate()}`.padStart(2, '0')}`;

  return `${start}~${end} 로 일정이 변경되었어요.`;
};

export default makePeriodChangeToastMessage;
