import styled, { css } from 'styled-components';
import openWindow from 'utils/openWindow';
import { footerIconsConstants } from 'constants/footerConstants';
import contents from 'static/images/contents.png';
import appDownloadQR from 'static/images/appDownloadQR.png';
import Typography from 'components/atoms/Typography';

const PCContents = () => {
  return (
    <>
      <Background className="abcccc" />
      <AppDownLoadQRContainer>
        <img src={appDownloadQR} alt="appDownloadQR" />
        <Typography
          weight="medium"
          variant="headline"
          color="darkGrey2"
          cssObject={css`
            margin: 16px 0;
          `}
        >
          캠핏 앱 설치하고
          <br />
          캠핑에 필요한 모든 순간을 함께해요
        </Typography>

        <Typography weight="regular" variant="body2" color="darkGrey2">
          QR 코드를 스캔하면 다운로드로 연결됩니다
        </Typography>
      </AppDownLoadQRContainer>
      {/* <Contents>
        <img src={contents} alt="contents" />
      </Contents> */}
      {/* <SNSIcons>
        {footerIconsConstants.map(sns => (
          <span
            className={
              sns.id === '2' ? 'insta-icon' : sns.id === '6' ? 'post-icon' : ''
            }
            onClick={openWindow(sns.url)}
            aria-hidden
            key={sns.id}
          >
            {sns.icon}
          </span>
        ))}
      </SNSIcons> */}
    </>
  );
};

export default PCContents;

export const Background = styled.div`
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme.newColors.lightBackground1};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @media screen and (max-width: 530px) {
    background-image: none;
  }
`;

const AppDownLoadQRContainer = styled.div`
  width: 300px;
  height: 286px;

  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  left: calc(50vw - 600px);
  bottom: 56px;

  padding: 24px;
  border-radius: 10px;

  background: ${({ theme }) => theme.newColors.white};

  img {
    width: 131px;
    height: auto;
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

const Contents = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  height: 100vh;
  left: calc(50vw - 480px);
  img {
    width: 283px;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const SNSIcons = styled.ul`
  position: fixed;
  height: 24px;
  left: calc(50vw - 480px);
  bottom: 50px;

  span {
    display: inline-block;
    height: 100%;
    cursor: pointer;
    margin-left: 22px;

    svg {
      width: 24px;
      height: 24px;
      fill: rgba(30, 99, 66, 1);
      opacity: 0.6;
    }

    :first-child {
      margin: 0;
    }

    /* &.insta-icon {
      margin-left: 8px;
    } */
    &.post-icon {
      margin-left: 14px;
      svg {
        width: 27px;
        height: 27px;
      }
    }
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
