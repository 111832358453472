import { Route, Redirect } from 'react-router-dom';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Loading from 'pages/Loading';

const FeatureRoute = (props: any) => {
  const {
    location,
    isPrivate,
    featureFlagsKey,
    featureComponent: FeatureComponent,
    oldComponent: OldComponent,
    ...rest
  } = props;
  const isLoggedIn = !!localStorage.getItem('jwt');

  const { flag, enableFeature, isLoading } = useFeatureFlags(featureFlagsKey);

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (isPrivate && !isLoggedIn) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          );
        }

        if (typeof flag === undefined || isLoading) {
          return <Loading />;
        }

        return enableFeature ? (
          <FeatureComponent enabledFeature {...routeProps} />
        ) : (
          <OldComponent {...routeProps} />
        );
      }}
    />
  );
};

export default FeatureRoute;
