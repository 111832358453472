import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string;
  ogTitle?: string;
  description?: string;
  url?: string;
  image?: string;
  type?: string;
  noIndex?: boolean;
}

/**
 * Note
 * SEO최적화를 위한 Helmet component, 기존 <Helmet />과 동일하게 title, meta Tag등 변경이 필요한 Page에서 사용.
 * 새로운 meta TAG 필요시 index.html에 추가 후 data-rh="true" 포함시킬 것. 중복 TAG 방지
 * noIndex가 true인 경우 robots, googlebot, yeti에 대해 noindex, nofollow 설정
 * 이양우 2023.01.31
 */
const SEOHelmet = ({
  title = '막힘없이 캠핑가자, 캠핏',
  ogTitle = '막힘없이 캠핑가자, 캠핏',
  description = '하나되는 캠핑 정보로 전국 캠핑장을 가장 쉽고 빠르게 예약 | 캠핑 예약, 캠핑 정보 추천까지 모두 한 곳에서',
  url = 'https://camfit.co.kr/',
  image = '%PUBLIC_URL%/camfitOgMeta800600.png',
  type = 'websit',
  noIndex = false,
}: Props) => {
  return (
    <Helmet>
      <title>{title}</title>

      {noIndex ? (
        // SEO 비활성화가 필요한 경우
        <>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="googlebot" content="noindex, nofollow" />
          <meta name="yeti" content="noindex, nofollow" />
        </>
      ) : (
        // 일반적인 SEO 메타 태그
        <>
          <meta property="og:title" content={ogTitle} />
          <meta
            name="description"
            property="og:description"
            content={description}
          />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={image} />
          <meta property="og:type" content={type} />
        </>
      )}
    </Helmet>
  );
};

export default SEOHelmet;
