import { takeLatest, all, put } from 'redux-saga/effects';
import {
  loginWithApple,
  loginWithGoogle,
  loginWithKakao,
  socialLoginConfirm,
  socialLoginRegister,
} from 'api';
import { registerShopbyRequest } from 'store/reducers/register';
import { createFetchAction } from 'store/sagas/createFetchAction';
import { getUserTrackingInfoRequest } from 'store/reducers/algoliaSearch';
import {
  IRegisterResponse,
  IResponse,
  ISocialLoginRegisterResponse,
  ISocialLogInResponse,
} from '@types';
import { getSourcePath, removeSourcePath } from 'utils/sessionStorageUtils';
import {
  loginWithGoogleRequest,
  loginWithGoogleSuccess,
  loginWithGoogleFailure,
  loginWithKakaoRequest,
  loginWithKakaoSuccess,
  loginWithKakaoFailure,
  loginWithAppleRequest,
  loginWithAppleSuccess,
  loginWithAppleFailure,
  setSocialLoginId,
  setSocialLoginProvider,
  setSocialLoginProviderEmail,
  socialLoginRegisterRequest,
  socialLoginRegisterSuccess,
  socialLoginRegisterFailure,
  socialLoginConfirmRequest,
  socialLoginConfirmSuccess,
  socialLoginConfirmFailure,
} from 'store/reducers/socialLogin';
import { setNeedCertification } from 'store/reducers/login';
import setReservationInfoState from 'utils/setReservationInfoState';
import { customHistory } from 'App';

const queryString = require('query-string');

interface LocationState {
  isAuthorized?: boolean;
  from: { pathname: string };
}

function* saveUserData(
  res: ISocialLogInResponse | IRegisterResponse | ISocialLoginRegisterResponse,
) {
  const { jwt, user } = yield res;

  const { email, nickname, thumbnail, brief, role, marketingAgreement } = user;

  if (role.name !== 'Authenticated') {
    alert('캠지기센터 아이디는 사용하실 수 없습니다');

    return;
  }

  // NOTE: 로그인 성공 시점에 샵바이 토큰이 없다면, 샵바이 회원가입
  const shopbyAccessToken: string | null | undefined =
    localStorage.getItem('shopbyAccessToken');

  if (!shopbyAccessToken) {
    yield put(registerShopbyRequest());
  }

  localStorage.setItem('jwt', jwt);
  localStorage.setItem('email', email);
  localStorage.setItem('nickname', nickname);

  if (brief) {
    localStorage.setItem('brief', brief);
  }

  if (thumbnail && thumbnail.url) {
    localStorage.setItem('thumbnail', thumbnail.url);
  }

  if (typeof marketingAgreement === 'boolean') {
    localStorage.setItem('marketingAgreement', String(marketingAgreement));
  }

  yield put(getUserTrackingInfoRequest());
}

function* loginSuccess(res: ISocialLogInResponse) {
  yield saveUserData(res);

  const locationState: LocationState = yield customHistory.location.state;

  if (!res.isCertified) {
    yield put(setNeedCertification(true));

    yield customHistory.push({
      pathname: '/mypage/info/edit',
      state: {
        from: { pathname: locationState?.from?.pathname },
        isAuthorized: locationState?.isAuthorized,
      },
    });

    return;
  }

  const isGoingToReservation = JSON.parse(
    sessionStorage.getItem('isGoingToReservation') || 'null',
  );

  const sourcePath = getSourcePath();
  const query = queryString.parse(customHistory.location.search);
  const { id: bookingId } = query;

  if (isGoingToReservation) {
    yield setReservationInfoState();
    sessionStorage.removeItem('isGoingToReservation');
    // 로그인 하지 않은 상태로 예약 하는 경우
    customHistory.push('/reservation');
  } else if (bookingId) {
    // 로그인 하지 않은 상태에서 예약페이지 접근시 쿼리로 bookignId 전달
    customHistory.push(`/reservation/result/${bookingId}`);
  } else if (locationState?.isAuthorized !== undefined) {
    // 로그인 하지 않은 상태로 예약 하는 경우
    customHistory.push('/reservation');
  } else if (locationState?.from.pathname) {
    // 로그인 하지 않은 상태로 <PrivateRoute/>로 점근하는 경우
    customHistory.push(locationState?.from.pathname);
  } else if (sourcePath) {
    // sourcePath 설정 되어 있는 경우
    yield removeSourcePath();
    yield customHistory.push(sourcePath);
  } else {
    customHistory.push('/');
  }
}

function* loginFail(res: Error | IResponse | ISocialLogInResponse) {
  const {
    nextRoute,
    provider,
    email: providerEmail, // 소셜에서 받아온 이메일
    socialLoginId,
  } = yield res;

  if (nextRoute) {
    if (provider) {
      yield put(setSocialLoginProvider(provider));
    }

    if (providerEmail) {
      yield put(setSocialLoginProviderEmail(providerEmail));
    }

    if (socialLoginId) {
      yield put(setSocialLoginId(socialLoginId));
    }

    yield customHistory.push(nextRoute);

    return;
  }

  if (res && res.message && typeof res.message === 'string') {
    if (res.message.includes('400')) {
      return;
    }
    yield alert(`${res.message}`);

    customHistory.push('/login');
  } else {
    yield alert('로그인 정보가 올바르지 않습니다.');
  }
}

function* socialLoginWithKakaoSaga() {
  yield takeLatest(
    loginWithKakaoRequest.type,
    createFetchAction(
      loginWithKakao,
      loginWithKakaoSuccess,
      loginWithKakaoFailure,
      loginSuccess,
      loginFail,
    ),
  );
}

function* socialLoginWithGoogleSaga() {
  yield takeLatest(
    loginWithGoogleRequest.type,
    createFetchAction(
      loginWithGoogle,
      loginWithGoogleSuccess,
      loginWithGoogleFailure,
      loginSuccess,
      loginFail,
    ),
  );
}

function* socialLoginWithAppleSaga() {
  yield takeLatest(
    loginWithAppleRequest.type,
    createFetchAction(
      loginWithApple,
      loginWithAppleSuccess,
      loginWithAppleFailure,
      loginSuccess,
      loginFail,
    ),
  );
}

function* socialLoginRegisterSaga() {
  yield takeLatest(
    socialLoginRegisterRequest.type,
    createFetchAction(
      socialLoginRegister,
      socialLoginRegisterSuccess,
      socialLoginRegisterFailure,
      function* success(res: ISocialLoginRegisterResponse) {
        const { flow } = res;

        if (flow === 'passConfirm' || flow === 'passwordConfirm') {
          return;
        }

        if (flow === 'register' || flow === 'connect') {
          yield saveUserData(res);
          yield customHistory.push('/sign-up/complete?isConnect=true');
        }
      },
    ),
  );
}

function* socialLoginConfirmSaga() {
  yield takeLatest(
    socialLoginConfirmRequest.type,
    createFetchAction(
      socialLoginConfirm,
      socialLoginConfirmSuccess,
      socialLoginConfirmFailure,
      function* success(res: ISocialLoginRegisterResponse) {
        yield saveUserData(res);
        yield customHistory.push('/sign-up/complete?isConnect=true');
      },
    ),
  );
}

export default function* socialLoginout() {
  yield all([
    socialLoginWithKakaoSaga(),
    socialLoginWithGoogleSaga(),
    socialLoginWithAppleSaga(),
    socialLoginRegisterSaga(),
    socialLoginConfirmSaga(),
  ]);
}
