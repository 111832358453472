import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealtimeReservationState } from 'store/types';
import {
  IRealtimeReservationData,
  IMedia,
  ICampExhibition,
  IRecommendKeywords,
  ICamp,
} from '@types';

export interface IExhibitionCampRequest {
  exhibitionCode: string;
  skip: number;
  limit: number;
}

const initialState: RealtimeReservationState = {
  recommendKeywords: [] as IRecommendKeywords[],
  banners: undefined,
  exhibitions: [
    {
      code: '',
      title: '',
      brief: '',
      camps: [],
      thumbnail: {} as IMedia,
      displayType: '',
      subMedia: {} as IMedia,
      numOfViewed: 0,
      numOfCamps: 0,
      locations: [],
    } as ICampExhibition,
  ],
  exhibitionMdPickCampList: [],
  exhibitionWeeklyPickCampList: [],
  error: '',
};

export const realtimeReservationSlice = createSlice({
  name: 'realtimeReservation',
  initialState,
  reducers: {
    getRealtimeReservationDataRequest: (state, action: PayloadAction) => {
      state.recommendKeywords = [] as IRecommendKeywords[];
      // state.banners = undefined;
      state.exhibitions = [] as ICampExhibition[];
      state.error = '';
    },
    getRealtimeReservationDataRequestSuccess: (
      state,
      action: PayloadAction<IRealtimeReservationData>,
    ) => {
      state.recommendKeywords = action.payload.recommendKeywords;
      state.banners = action.payload.banners;
      state.exhibitions = action.payload.exhibitions as ICampExhibition[];
      state.error = '';
    },
    getRealtimeReservationDataRequestFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.recommendKeywords = [];
      state.banners = [];
      state.exhibitions = [];
      state.error = action.payload;
    },
    getMdPickCampListRequest: (
      state,
      action: PayloadAction<IExhibitionCampRequest>,
    ) => {
      state.error = '';
    },
    getMdPickCampListSuccess: (state, action: PayloadAction<ICamp[]>) => {
      state.exhibitionMdPickCampList = action.payload;
    },
    getMdPickCampListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    getWeeklyPickCampListRequest: (
      state,
      action: PayloadAction<IExhibitionCampRequest>,
    ) => {
      state.error = '';
    },
    getWeeklyPickCampListSuccess: (state, action: PayloadAction<ICamp[]>) => {
      state.exhibitionWeeklyPickCampList = action.payload;
    },
    getWeeklyPickCampListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  getRealtimeReservationDataRequest,
  getRealtimeReservationDataRequestSuccess,
  getRealtimeReservationDataRequestFailure,
  getMdPickCampListRequest,
  getMdPickCampListSuccess,
  getMdPickCampListFailure,
  getWeeklyPickCampListRequest,
  getWeeklyPickCampListSuccess,
  getWeeklyPickCampListFailure,
} = realtimeReservationSlice.actions;

export default realtimeReservationSlice.reducer;
