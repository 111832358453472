import { all, takeLatest } from 'redux-saga/effects';
import { getSearchMainTheme, getThemeCampList } from 'api';
import {
  createFetchAction,
  createFetchActionWithKey,
} from 'store/sagas/createFetchAction';
import {
  getMoreThemeCampListRequest,
  getThemeCampListFailure,
  getThemeCampListRequest,
  getThemeCampListSuccess,
  getThemeListFailure,
  getThemeListMoreRequest,
  getThemeListRequest,
  getThemeListSuccess,
} from 'store/theme/themeReducer';

function* getThemeListSaga() {
  yield takeLatest(
    getThemeListRequest.type,
    createFetchAction(
      getSearchMainTheme,
      getThemeListSuccess,
      getThemeListFailure,
    ),
  );
}

function* getThemeListMoreSaga() {
  yield takeLatest(
    getThemeListMoreRequest.type,
    createFetchAction(
      getSearchMainTheme,
      getThemeListSuccess,
      getThemeListFailure,
    ),
  );
}

function* getThemeCampListSaga() {
  yield takeLatest(
    getThemeCampListRequest.type,
    createFetchActionWithKey(
      getThemeCampList,
      getThemeCampListSuccess,
      getThemeCampListFailure,
    ),
  );
}

function* getMoreThemeCampListSaga() {
  yield takeLatest(
    getMoreThemeCampListRequest.type,
    createFetchActionWithKey(
      getThemeCampList,
      getThemeCampListSuccess,
      getThemeCampListFailure,
    ),
  );
}

export default function* themeSaga() {
  yield all([
    getThemeListSaga(),
    getThemeListMoreSaga(),
    getThemeCampListSaga(),
    getMoreThemeCampListSaga(),
  ]);
}
