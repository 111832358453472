import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICollectionStoreState,
  IGetCampSearchCampRequestPayload,
} from 'store/types';
import {
  TCollectionPathKey,
  ICollectionMain,
  ICollectionDetail,
  ISearchResultCamp,
} from '../../@types/index';

const initialState: ICollectionStoreState = {
  search: {
    paging: { skip: 0, limit: 5, hasNext: true },
    data: undefined,
    error: null,
  },
  detail: {
    paging: { skip: 0, limit: 10, hasNext: true },
    data: undefined,
    error: null,
  },
};

export const collectionSlice = createSlice({
  name: 'collectionList',
  initialState,
  reducers: {
    getCollectionsRequest: (
      state,
      action: PayloadAction<{
        key: keyof ICollectionStoreState;
        skip: number;
        limit: number;
      }>,
    ) => {
      const { key, skip, limit } = action.payload;
      const targetCollection = state[key];
      if (targetCollection) {
        const paging = targetCollection.paging ?? {
          skip,
          limit,
          hasNext: true,
        };
        targetCollection.paging = paging;
      }
    },
    getCollectionsMoreRequest: (
      state,
      action: PayloadAction<{
        key: keyof ICollectionStoreState;
        skip: number;
        limit: number;
      }>,
    ) => {
      const { key, skip, limit } = action.payload;
      const targetCollection = state[key];
      if (targetCollection) {
        const paging = targetCollection.paging ?? {
          skip,
          limit,
          hasNext: true,
        };
        targetCollection.paging = paging;
      }
    },
    getCollectionsSuccess: (
      state,
      action: PayloadAction<{
        key: keyof ICollectionStoreState;
        data: {
          data: ICollectionMain[];
          hasNext: boolean;
        };
      }>,
    ) => {
      const { key, data } = action.payload;
      const targetPath = state[key];
      if (targetPath) {
        const targetCollection = targetPath?.data as ICollectionMain[];
        if (!targetPath.data) {
          targetPath.data = data.data;
        } else {
          targetCollection.push(...data.data);
        }
        targetPath.paging = {
          ...targetPath.paging,
          skip: targetPath.paging.skip + data.data.length,
          hasNext: data.hasNext,
        };
      }
    },
    getCollectionsFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string;
      }>,
    ) => {
      const { key, error } = action.payload;
      const targetPathkey = key as keyof ICollectionStoreState;
      const targetPath = state[targetPathkey];
      if (targetPath) {
        targetPath.error = error;
      }
    },
    getCollectionItemsReset: (
      state,
      action: PayloadAction<{
        targetKey: TCollectionPathKey;
      }>,
    ) => {
      const targetPath = state[action.payload.targetKey];

      if (targetPath) {
        const targetCollection = targetPath.data as ICollectionDetail;
        if (targetCollection) {
          if (targetCollection.camps) targetCollection.camps = [];
          targetPath.paging = { skip: 0, limit: 10, hasNext: true };
        }
      }
    },
    getCollectionItemsRequest: (
      state,
      action: PayloadAction<{
        targetKey: TCollectionPathKey;
        requestPayload: IGetCampSearchCampRequestPayload;
        id: string;
        skip: number;
        limit: number;
      }>,
    ) => {},
    getCollectionItemsMoreRequest: (
      state,
      action: PayloadAction<{
        targetKey: TCollectionPathKey;
        requestPayload: IGetCampSearchCampRequestPayload;
        id: string;
        skip: number;
        limit: number;
      }>,
    ) => {},
    getCollectionItemsSuccess: (
      state,
      action: PayloadAction<{
        key: keyof ICollectionStoreState;
        data: {
          data: ISearchResultCamp[];
          hasNext: boolean;
        };
        requestPayload: { targetKey: TCollectionPathKey; id: string };
      }>,
    ) => {
      const {
        data,
        requestPayload: { targetKey, id },
      } = action.payload;
      const targetPath = state[targetKey];

      if (targetPath) {
        const targetCollection = targetPath.data as ICollectionDetail;
        if (!targetCollection.camps) {
          targetCollection.camps = data.data;
        } else {
          targetCollection.camps.push(...data.data);
        }
        targetPath.paging = {
          limit: targetPath.paging.limit,
          skip: targetPath.paging.skip + data.data.length,
          hasNext: data.hasNext,
        };
      }
    },
    getCollectionItemsFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string;
      }>,
    ) => {
      const { key, error } = action.payload;
      const targetPathkey = key as keyof ICollectionStoreState;
      const targetPath = state[targetPathkey];
      if (targetPath) {
        targetPath.error = error;
      }
    },
    getCollectionDetailRequest: (
      state,
      action: PayloadAction<{
        key: keyof ICollectionStoreState;
        requestPayload: IGetCampSearchCampRequestPayload;
        id: string;
      }>,
    ) => {},
    getCollectionDetailSuccess: (
      state,
      action: PayloadAction<{
        key: keyof ICollectionStoreState;
        data: ICollectionDetail;
      }>,
    ) => {
      const { key, data } = action.payload;
      const targetPath = state[key];
      if (targetPath) {
        targetPath.data = { ...data, camps: [] };
      }
    },
    getCollectionDetailFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string;
      }>,
    ) => {
      const { key, error } = action.payload;
      const targetPathkey = key as keyof ICollectionStoreState;
      const targetPath = state[targetPathkey];
      if (targetPath) {
        targetPath.error = error;
      }
    },
  },
});

export const {
  getCollectionsRequest,
  getCollectionsMoreRequest,
  getCollectionsSuccess,
  getCollectionsFailure,
  getCollectionItemsReset,
  getCollectionItemsRequest,
  getCollectionItemsMoreRequest,
  getCollectionItemsSuccess,
  getCollectionItemsFailure,
  getCollectionDetailRequest,
  getCollectionDetailSuccess,
  getCollectionDetailFailure,
} = collectionSlice.actions;

export default collectionSlice.reducer;
