import { takeLatest, all } from 'redux-saga/effects';
import {
  getCollectionsRequest,
  getCollectionsSuccess,
  getCollectionsFailure,
  getCollectionItemsRequest,
  getCollectionItemsSuccess,
  getCollectionItemsFailure,
  getCollectionDetailRequest,
  getCollectionDetailSuccess,
  getCollectionDetailFailure,
  getCollectionItemsMoreRequest,
  getCollectionsMoreRequest,
} from 'store/collection/reducer';
import { createFetchActionWithKey } from 'store/sagas/createFetchAction';
import {
  getMainCollections,
  getCollectionItems,
  getCollectionDetail,
} from 'api';

function* getCollectionsSaga() {
  yield takeLatest(
    [getCollectionsRequest.type, getCollectionsMoreRequest.type],
    createFetchActionWithKey(
      getMainCollections,
      getCollectionsSuccess,
      getCollectionsFailure,
    ),
  );
}

function* getCollectionItemsSaga() {
  yield takeLatest(
    [getCollectionItemsRequest.type, getCollectionItemsMoreRequest.type],
    createFetchActionWithKey(
      getCollectionItems,
      getCollectionItemsSuccess,
      getCollectionItemsFailure,
    ),
  );
}
function* getCollectionDetailSaga() {
  yield takeLatest(
    getCollectionDetailRequest.type,
    createFetchActionWithKey(
      getCollectionDetail,
      getCollectionDetailSuccess,
      getCollectionDetailFailure,
    ),
  );
}

export default function* collectionSaga() {
  yield all([
    getCollectionsSaga(),
    getCollectionItemsSaga(),
    getCollectionDetailSaga(),
  ]);
}
