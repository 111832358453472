import { useCallback, RefObject } from 'react';

const useLazyLoading = (blankImage?: string, onImageRender?: () => void) => {
  const onLazyLoading = useCallback((target: RefObject<HTMLImageElement>) => {
    const options = { threshold: 0.1 };
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fadeIn');
          const image = entry.target as HTMLImageElement;
          image.src = image.dataset.src as string;
          if (blankImage) {
            image.onerror = () => {
              image.src = blankImage;
            };
          }
          if (onImageRender) onImageRender();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(target.current as Element);

    return () => observer && observer.disconnect();
  }, []);
  return { onLazyLoading };
};

export default useLazyLoading;
