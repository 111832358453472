import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterState } from 'store/types';
import { ILocationTable, ICity } from '@types';

const initialState: FilterState = {
  locations: [],
  filterValues: {
    types: [],
    floorTypes: [],
    surroundingLeisureTypes: [],
    services: [],
    enterTypes: [],
    activities: [],
    parkingTypes: [],
  },
  selectedLocations: { city: {} as ICity, subCity: [] },
  error: '',
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    getLocationTableRequest: (state, action: PayloadAction) => {
      state.locations = [];
      state.error = '';
    },

    getLocationTableSuccess: (
      state,
      action: PayloadAction<ILocationTable[]>,
    ) => {
      state.locations = action.payload;
      state.error = '';
    },

    getLocationTableFailure: (state, action: PayloadAction<Error | string>) => {
      state.locations = [];
      state.error = action.payload;
    },
  },
});

export const {
  getLocationTableRequest,
  getLocationTableFailure,
  getLocationTableSuccess,
} = filterSlice.actions;

export default filterSlice.reducer;
