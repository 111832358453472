import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGetCampZoneFilterRequestPayload,
  IGetGeoCampsRequestPayload,
  ISearchGeoRequestPayload,
  SearchGeoState,
} from 'store/types';
import { ICampMapPin, INewCampGeo, ISearchGeoResponse } from '@types';

const initialState: SearchGeoState = {
  // 예전 버전
  campGeoList: [],
  totalCount: 0,
  page: 0,
  totalPage: 0,
  pageSize: 0,
  selectedMarkerIndex: -1,
  curLocation: { latitude: 0, longitude: 0 },
  curZoom: 7,
  // 새로운 버전
  filteredCampList: undefined,
  allCampPinDataList: undefined,
  selectedSingleCampData: undefined,
  selectedCampCardData: undefined,
  error: '',
};

export const searchGeoSlice = createSlice({
  name: 'searchGeo',
  initialState,
  reducers: {
    searchGeoRequest: (
      state,
      action: PayloadAction<ISearchGeoRequestPayload>,
    ) => {
      state.error = '';
    },

    searchGeoSuccess: (state, action: PayloadAction<ISearchGeoResponse>) => {
      if (action.payload.page > 1)
        state.campGeoList = state.campGeoList.concat(action.payload.camps);
      else state.campGeoList = action.payload.camps;
      state.page = action.payload.page;
      state.totalPage = action.payload.totalPage;
      state.pageSize = action.payload.pageSize;
      state.totalCount = action.payload.totalCount;
      state.error = '';
    },
    searchGeoFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    searchSetSelectedMarkerIndex: (state, action: PayloadAction<number>) => {
      state.selectedMarkerIndex = action.payload;
    },
    searchSetCurLocation: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.curLocation.latitude = action.payload.latitude;
      state.curLocation.longitude = action.payload.longitude;
    },
    getFilteredCampsListInGeoRequest: (
      state,
      action: PayloadAction<IGetGeoCampsRequestPayload>,
    ) => {
      state.error = '';
    },

    getFilteredCampsListInGeoSuccess: (
      state,
      action: PayloadAction<INewCampGeo[]>,
    ) => {
      state.filteredCampList = action.payload;
      state.error = '';
    },
    getFilteredCampsListInGeoFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    searchSetCurZoom: (state, action: PayloadAction<number>) => {
      state.curZoom = action.payload;
    },
    getAllCampMapPinsRequest: (
      state,
      action: PayloadAction<IGetGeoCampsRequestPayload>,
    ) => {
      state.error = '';
    },

    getAllCampMapPinsSuccess: (state, action: PayloadAction<ICampMapPin[]>) => {
      state.allCampPinDataList = action.payload;
      state.error = '';
    },
    getAllCampMapPinsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    getCampInGeoRequest: (
      state,
      action: PayloadAction<IGetCampZoneFilterRequestPayload>,
    ) => {
      state.error = '';
    },

    getCampInGeoSuccess: (state, action: PayloadAction<INewCampGeo>) => {
      state.selectedSingleCampData = action.payload;
      state.error = '';
    },
    getCampInGeoFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    setSelectedCampCardData: (
      state,
      action: PayloadAction<undefined | INewCampGeo>,
    ) => {
      state.selectedCampCardData = action.payload;
    },
  },
});

export const {
  searchGeoRequest,
  searchGeoSuccess,
  searchGeoFailure,
  searchSetSelectedMarkerIndex,
  searchSetCurLocation,
  searchSetCurZoom,
  getFilteredCampsListInGeoRequest,
  getFilteredCampsListInGeoSuccess,
  getFilteredCampsListInGeoFailure,
  getAllCampMapPinsRequest,
  getAllCampMapPinsSuccess,
  getAllCampMapPinsFailure,
  getCampInGeoRequest,
  getCampInGeoSuccess,
  getCampInGeoFailure,
  setSelectedCampCardData,
} = searchGeoSlice.actions;

export default searchGeoSlice.reducer;
