import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICamfitMedia } from '@types';
import {
  IGetCampSearchCampRequestPayload,
  IThemeCampListResponsePayload,
  IThemeListResponsePayload,
  IThemeState,
  TOpenSectionName,
} from 'store/types';

import AllThemeIcon from 'static/images/searchTheme/all.svg';

const defaultAllTheme = {
  id: 'all',
  icon: {
    id: '671b2e1f609887bb14ae52a5',
    url: AllThemeIcon,
    width: 800,
    height: 800,
    formats: {
      thumbnail: {
        url: AllThemeIcon,
        width: 156,
        height: 156,
      },
      small: {
        url: AllThemeIcon,
        width: 500,
        height: 500,
      },
      medium: {
        url: AllThemeIcon,
        width: 750,
        height: 750,
      },
      large: {},
    },
  } as ICamfitMedia,
  name: '전체',
  description: '',
  exposeFilter: [
    'keyword',
    'location',
    'date',
    'people',
    'filter',
  ] as TOpenSectionName[],
  locations: [],
};

const initialState: IThemeState = {
  themeIdList: ['all'],
  themeListInfo: [defaultAllTheme],
  themeListPaging: { skip: 0, limit: 10, hasNext: true },

  themeData: {
    all: {
      paging: { skip: 0, limit: 10, hasNext: false },
      data: [],
      error: null,
    },
  },
  error: null,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    getThemeListRequest: (
      state: IThemeState,
      action: PayloadAction<
        { skip: number; limit: number } & IGetCampSearchCampRequestPayload
      >,
    ) => {
      state.themeIdList = initialState.themeIdList;
      state.themeListPaging = initialState.themeListPaging;
      state.themeListInfo = initialState.themeListInfo;
      state.error = initialState.error;

      state.themeListPaging = {
        ...state.themeListPaging,
        skip: action.payload.skip,
        limit: action.payload.limit,
      };
    },
    getThemeListMoreRequest: (
      state: IThemeState,
      action: PayloadAction<
        { skip: number; limit: number } & IGetCampSearchCampRequestPayload
      >,
    ) => {
      state.themeListPaging = {
        ...state.themeListPaging,
        skip: action.payload.skip,
      };
    },
    getThemeListSuccess: (
      state: IThemeState,
      action: PayloadAction<IThemeListResponsePayload>,
    ) => {
      state.themeIdList = [
        ...state.themeIdList,
        ...action.payload.data.map(theme => theme.id),
      ];
      state.themeListInfo = [...state.themeListInfo, ...action.payload.data];
      state.themeListPaging.hasNext = action.payload.hasNext;
      state.error = null;
    },
    getThemeListFailure: (
      state: IThemeState,
      action: PayloadAction<Error | string>,
    ) => {
      state.themeIdList = initialState.themeIdList;
      state.themeListPaging = initialState.themeListPaging;
      state.themeListInfo = initialState.themeListInfo;
      state.error = action.payload;
    },

    getThemeCampListRequest: (
      state,
      action: PayloadAction<{
        key: string;
        skip: number;
        limit: number;
        searchQuery: string; // 현재 검색 쿼리 추가
      }>,
    ) => {
      const { key, skip, limit, searchQuery } = action.payload;
      state.themeData[key] = {
        paging: { skip, limit, hasNext: true },
        data: [],
        error: null,
        lastSearchQuery: searchQuery,
      };
    },
    getMoreThemeCampListRequest: (
      state,
      action: PayloadAction<{
        key: string;
        skip: number;
        limit: number;
      }>,
    ) => {
      const { key, skip, limit } = action.payload;
      state.themeData[key].paging = { skip, limit, hasNext: true };
      state.themeData[key].error = null;
    },
    getThemeCampListSuccess: (
      state,
      action: PayloadAction<{
        key: string;
        data: IThemeCampListResponsePayload;
      }>,
    ) => {
      const { key, data } = action.payload;

      state.themeData[key].data = [...state.themeData[key].data, ...data.data];

      state.themeData[key].paging = {
        ...state.themeData[key].paging,
        hasNext: data.hasNext,
      };

      state.themeData[key].error = null;
    },
    getThemeCampListFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string;
      }>,
    ) => {
      const { key, error } = action.payload;

      state.themeData[key].error = error;
    },
  },
});

export const {
  getThemeListRequest,
  getThemeListMoreRequest,
  getThemeListSuccess,
  getThemeListFailure,

  getThemeCampListRequest,
  getMoreThemeCampListRequest,
  getThemeCampListSuccess,
  getThemeCampListFailure,
} = themeSlice.actions;

export default themeSlice.reducer;
