import axios, { AxiosRequestConfig } from 'axios';
import { getShopbyServerURL } from 'server';
import {
  IGetShopbyMyReviewListRequestPayload,
  IGetShopbyReviewDetailRequestPayload,
  IGetShopbyReviewableItemListRequestPayload,
  IPostShopbyReviewableItemRequestPayload,
  IUpdateShopbyMyReviewRequestPayload,
  IShopbyReviewListRequestPayload,
  IShopbyPhotoReviewListRequestPayload,
  IShopbyPhotoReviewDetailRequestPayload,
  IShopbyPostReviewReportRequestPayload,
  IShopbyExhibitionRequestPayload,
  IShopbyExhibitionDetailRequestPayload,
  IShopbyBestRevewRequestPayload,
} from 'store/types';
import { ERROR } from 'constants/globalPhrases';
import { getShopbyClientId } from 'utils/shopby/getShopbyENV';
import {
  IShopbyGetOrderDetailRequest,
  IShopbyGetOrderListRequest,
  IShopbyPostOrderSheet,
  IShopbyUpdateOrderAddressRequest,
  ICreateShopbyUserAddressRequestPayload,
  ICreateShopbyCancelClaimsRequestPayload,
  IEstimateShopbyClaimPriceRequestPayload,
  IShopbyGetOrderClaimInfoRequest,
  IShopbyZipCodeRequestPayload,
  IGetShopbyMyInquiryRequestPayload,
  IPostShopbyItemInquiryRequestPayload,
  IEditShopbyItemInquiryRequestPayload,
  IEstimateShopbyClaimPriceForOneRequestPayload,
  ICreateShopbyReturnClaimsRequestPayload,
  ICreateShopbyExchangeClaimsRequestPayload,
  IShopbyGetAccumulationsRequestPayload,
  IShopbyGetAccumulationsSummaryRequestPayload,
  IShopbyProductSearchRequest,
  IShopbyProductSearchResponse,
} from '../@types/index';

const provider = 'ncpstore';

const shopbyBaseApi = axios.create({
  baseURL: getShopbyServerURL(),
  headers: {
    accept: 'application/json',
    Version: '1.0',
    clientId: getShopbyClientId(),
    platform: 'PC',
    'Cache-Control': 'no-cache',
  },
});

const shopbyBaseApiWithAccessToken = axios.create({
  baseURL: getShopbyServerURL(),
  headers: {
    accept: 'application/json',
    Version: '1.0',
    clientId: getShopbyClientId(),
    platform: 'PC',
    'Cache-Control': 'no-cache',
  },
});

const shopbyBaseApiV2 = axios.create({
  baseURL: getShopbyServerURL(),
  headers: {
    accept: 'application/json',
    Version: '2.0',
    clientId: getShopbyClientId(),
    platform: 'PC',
    'Cache-Control': 'no-cache',
  },
});

export const getShopbyAccessToken = async () => {
  let jwt = localStorage.getItem('jwt');

  if (jwt) {
    jwt = jwt.replace(/"/g, '');
  }

  if (!jwt) {
    // 캠핏 미로그인 시
    return;
  }

  const res = await shopbyBaseApi.post<any>(`/oauth/openid`, {
    provider,
    keepLogin: true,
    openAccessToken: `Bearer ${jwt}`,
  });

  return res;
};

shopbyBaseApiWithAccessToken.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let shopbyAccessToken: string | null | undefined =
      localStorage.getItem('shopbyAccessToken');

    if (shopbyAccessToken) {
      shopbyAccessToken = shopbyAccessToken.replace(/"/g, '');
    } else {
      const res = await getShopbyAccessToken();

      if (!res) {
        // NOTE : 캠핏 미로그인 시, 요청 X 페이지 리다이렉트
        alert(ERROR.EXPIRE);
        window.location.href = '/login';
        return;
      }

      if (!res.data?.accessToken || res.data?.expireIn <= 0) {
        const customShopbyError = new Error(
          '샵바이 accessToken 만료 혹은 획득 실패',
        );
        customShopbyError.message = ERROR.COMMON;
        throw customShopbyError;
      }

      localStorage.setItem('shopbyAccessToken', res?.data?.accessToken);
      shopbyAccessToken = res?.data?.accessToken;
    }

    config.headers.accessToken = shopbyAccessToken
      ? `${shopbyAccessToken}`
      : '';
    return config;
  },
  async error => {
    return Promise.reject(error);
  },
);

shopbyBaseApiWithAccessToken.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  },
);

export const getNullalbeShopbyAccessToken = async () => {
  let jwt = localStorage.getItem('jwt');

  if (!jwt) {
    return;
  }

  jwt = jwt.replace(/"/g, '');

  const res = await shopbyBaseApi.post<any>(`/oauth/openid`, {
    provider,
    keepLogin: true,
    openAccessToken: `Bearer ${jwt}`,
  });

  return res;
};

export const getMalls = async () => {
  const res = await shopbyBaseApi.get('/malls');

  return res.data;
};

export const getItemOption = async (itemNo: string) => {
  const res = await shopbyBaseApi.get(`/products/${itemNo}/options`);
  return res.data;
};

export const getOrderSheetNo = async (params: IShopbyPostOrderSheet) => {
  const res = await shopbyBaseApiWithAccessToken.post('/order-sheets', params);
  return res.data;
};

export const getShopbyUserAddress = async () => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/shipping-addresses',
  );

  return res.data;
};

export const createShopbyUserAddress = async (
  params: ICreateShopbyUserAddressRequestPayload,
) => {
  // NOTE. 샵바이 API 명세와 다른 부분이 있어 추가함 , 2023.04.27, 이양우
  const body = {
    ...params,
    addressType: 'BOOK',
    receiverContact2: '',
    customsIdNumber: '',
  };

  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/shipping-addresses`,
    body,
  );

  return res.data;
};

export const updateShopbyUserAddress = async (params: {
  addressNo: string;
  addressInfo: ICreateShopbyUserAddressRequestPayload;
}) => {
  const { addressNo, addressInfo } = params;

  const body = {
    ...addressInfo,
    addressType: 'BOOK',
    receiverContact2: '',
    customsIdNumber: '',
  };

  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/shipping-addresses/${addressNo}`,
    body,
  );

  return res.data;
};

export const setDefalutShopbyUserAddress = async (addressNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/shipping-addresses/${addressNo}/default`,
  );

  return res.data;
};

export const deleteShopbyUserAddress = async (addressNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.delete(
    `/profile/shipping-addresses/${addressNo}`,
  );

  return res.data;
};

export const getShopbyOrderCountSummary = async (params: {
  startYmd?: string;
  endYmd?: string;
}) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/order-options/summary/status',
    {
      params,
    },
  );

  return res.data;
};

export const getShopbyMyOrders = async (params: IShopbyGetOrderListRequest) => {
  const res = await shopbyBaseApiWithAccessToken.get('/profile/orders', {
    params,
  });
  return res.data;
};

export const getShopbyMyOrderDetail = async (
  params: IShopbyGetOrderDetailRequest,
) => {
  const { orderNo, orderRequestType } = params;
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/orders/${orderNo}`,
    { params: { orderRequestType } },
  );

  return res.data;
};

export const getShopbyItemReviewList = async (
  params: IShopbyReviewListRequestPayload,
) => {
  const { productNo, parameters } = params;

  let urlParams = '';

  for (const [key, value] of Object.entries(parameters)) {
    if (key === 'orderDirertion') {
      urlParams += `order.direction=${value}&`;
    } else if (key === 'orderBy') {
      urlParams += `order.by=${value}&`;
    } else if (value) {
      urlParams += `${key}=${value}&`;
    }
  }

  const res = await shopbyBaseApi.get(
    `/products/${productNo}/product-reviews?${urlParams}`,
  );

  return res.data;
};

export const getShopbyPhotoReviewList = async (
  params: IShopbyPhotoReviewListRequestPayload,
) => {
  const { productNo, parameters } = params;

  const res = await shopbyBaseApi.get(`/products/${productNo}/photo-reviews`, {
    params: parameters,
  });

  return res.data;
};

export const getShopbyPhotoReviewDetail = async (
  params: IShopbyPhotoReviewDetailRequestPayload,
) => {
  const { productNo, reviewNo } = params;

  const res = await shopbyBaseApi.get(
    `/products/${productNo}/product-reviews/${reviewNo}`,
  );

  return res.data;
};

export const getOrderSheetInfo = async (orderSheetNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/order-sheets/${orderSheetNo}`,
  );

  return res.data;
};
export const calculatedOrderSheet = async (params: any) => {
  const { orderSheetNo, data: payload } = params;

  const res = await shopbyBaseApiWithAccessToken.post(
    `/order-sheets/${orderSheetNo}/calculate`,
    payload,
  );

  return res.data;
};

export const updateShopbyOrderAddress = async (
  params: IShopbyUpdateOrderAddressRequest,
) => {
  const { orderNo, data: payload } = params;
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/orders/${orderNo}/deliveries`,
    payload,
  );

  return res.data;
};

export const updateShopbyPurchaseConfirm = async (orderOptionNo: number) => {
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/order-options/${orderOptionNo}/confirm`,
  );

  return res.data;
};
export const getShopbyInfoForClaim = async (
  params: IShopbyGetOrderClaimInfoRequest,
) => {
  const { orderOptionNo, queryData: payload } = params;
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/order-options/${orderOptionNo}/claims`,
    { params: payload },
  );

  return res.data;
};

export const estimateShopbyPriceInfoForClaim = async (
  payload: IEstimateShopbyClaimPriceRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/claims/estimate`,
    payload,
  );

  return res.data;
};

export const estimateShopbyPriceInfoForOneOptionClaim = async (
  params: IEstimateShopbyClaimPriceForOneRequestPayload,
) => {
  const { orderOptionNo, ...payload } = params;
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/order-options/${orderOptionNo}/claims/estimate`,
    { params: payload },
  );

  return res.data;
};

export const postShopbyClaim = async (
  payload:
    | ICreateShopbyCancelClaimsRequestPayload
    | ICreateShopbyReturnClaimsRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/claims/${payload.claimType.toLowerCase()}`,
    payload,
  );

  return res.data;
};

export const postShopbyExchangeClaim = async (
  payload: ICreateShopbyExchangeClaimsRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/order-options/${payload.orderOptionNo}/claims/exchange`,
    payload,
  );

  return res.data;
};

export const getShopbyClaimDetailByClaimNo = async (claimNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/claims/${claimNo}/result`,
  );

  return res.data;
};

export const getShopbyClaimDetailByOrderOptionNo = async (
  orderOptionNo: string,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/order-options/${orderOptionNo}/claims/result`,
  );

  return res.data;
};

export const checkIsShopbyClaimWithdrawable = async (claimNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/claims/${claimNo}/check-withdraw`,
  );

  return res.data;
};

export const withdrawShopbyClaim = async (claimNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/claims/${claimNo}/withdraw`,
  );

  return res.data;
};

export const getShopbySearchZipCode = async (
  params: IShopbyZipCodeRequestPayload,
) => {
  const res = await shopbyBaseApi.get(`/addresses/search`, { params });

  return res.data;
};

export const uploadShopbyImageFile = async (data: FormData) => {
  const res = await shopbyBaseApi.post('/files/images ', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'multipart/form-data',
    },
  });

  return res.data;
};

export const uploadShopbyImageFiles = async (data: FormData[]) => {
  const responseData = await Promise.all(
    data.map(formData => {
      return new Promise(resolve => {
        const imageUrlData = uploadShopbyImageFile(formData);
        resolve(imageUrlData);
      });
    }),
  );

  return responseData;
};

export const getShopbyMyInquiry = async (
  params: IGetShopbyMyInquiryRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/product-inquiries',
    { params },
  );

  return res.data;
};

export const postShopbyMyInquiry = async (
  params: IPostShopbyItemInquiryRequestPayload,
) => {
  const { productNo } = params;
  const res = await shopbyBaseApiWithAccessToken.post(
    `/products/${productNo}/inquiries`,
    params,
  );

  return res.data;
};

export const editShopbyMyInquiry = async (params: {
  inquiryNo: number;
  data: IEditShopbyItemInquiryRequestPayload;
}) => {
  const { inquiryNo, data } = params;

  const res = await shopbyBaseApiWithAccessToken.put(
    `/products/inquiries/${inquiryNo}`,
    data,
  );

  return res.data;
};

export const deleteShopbyMyInquiry = async (inquiryNo: number) => {
  const res = await shopbyBaseApiWithAccessToken.delete(
    `/products/inquiries/${inquiryNo}`,
  );

  return res.data;
};

export const getShopbyMyReviewableList = async (
  params: IGetShopbyReviewableItemListRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/order-options/product-reviewable',
    { params },
  );

  return res.data;
};

export const postShopbyMyItemReview = async (
  params: IPostShopbyReviewableItemRequestPayload,
) => {
  const { productNo, ...body } = params;

  const res = await shopbyBaseApiWithAccessToken.post(
    `/products/${productNo}/product-reviews`,
    body,
  );

  return res.data;
};

export const getShopbyMyReviewedList = async (
  params: IGetShopbyMyReviewListRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/product-reviews',
    { params },
  );

  return res.data;
};

export const getShopbyReviewDetail = async (
  params: IGetShopbyReviewDetailRequestPayload,
) => {
  const { productNo, reviewNo } = params;

  const res = await shopbyBaseApiWithAccessToken.get(
    `/products/${productNo}/product-reviews/${reviewNo}`,
  );

  return res.data;
};

export const updateShopbyMyItemReview = async (
  params: IUpdateShopbyMyReviewRequestPayload,
) => {
  const { productNo, reviewNo, ...body } = params;

  const res = await shopbyBaseApiWithAccessToken.put(
    `/products/${productNo}/product-reviews/${reviewNo}`,
    body,
  );

  return res.data;
};

export const getShopbyMyPointList = async (
  params: IShopbyGetAccumulationsRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get('/profile/accumulations', {
    params,
  });

  return res.data;
};

export const getShopbyMyPointSummary = async (
  params: IShopbyGetAccumulationsSummaryRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/accumulations/summary',
    { params },
  );

  return res.data;
};

export const postShopbyReviewReport = async (
  params: IShopbyPostReviewReportRequestPayload,
) => {
  const { productNo, reviewNo, content, reportReasonCd } = params;

  const body = {
    reviewNo,
    content,
    reportReasonCd,
  };

  const res = await shopbyBaseApiWithAccessToken.post(
    `/products/${productNo}/product-reviews/${reviewNo}/report`,
    body,
  );

  return res.data;
};

export const getShopbyItemExhibitionList = async (
  params: IShopbyExhibitionRequestPayload,
) => {
  const { pageNumber, pageSize, categoryNos, eventYn, progressStatus } = params;

  let queryParams = `page.number=${pageNumber}&page.size=${pageSize}${
    categoryNos ? `&categoryNos=${categoryNos}` : ''
  }${eventYn ? `&eventYn=${eventYn}` : ''}${
    progressStatus ? `&progressStatus=${progressStatus}` : ''
  }`;

  for (const [key, value] of Object.entries(params)) {
    if (key === 'keywordInfoType') {
      queryParams += `&keywordInfo.type=${value}`;
    } else if (key === 'keywordInfoValue') {
      queryParams += `&keywordInfo.value=${value}`;
    } else if (key === 'orderBy') {
      queryParams += `&order.by=${value}`;
    } else if (key === 'orderDirection') {
      queryParams += `&order.direction=${value}`;
    }
  }

  const res = await shopbyBaseApiV2.get(`/display/events?${queryParams}`);

  return res.data;
};

export const getShopbyItemExhibitionDetail = async (
  params: IShopbyExhibitionDetailRequestPayload,
) => {
  const { eventNo, hasProductDetail, saleStatus, order, soldout } = params;

  const res = await shopbyBaseApi.get(`/display/events/${eventNo}`, {
    params: {
      hasProductDetail,
      saleStatus,
      order,
      soldout,
    },
  });

  return res.data;
};

export const getShopbyPrivagteExhibitionDetails = async (
  eventNumbers: string[],
) => {
  const res = await Promise.all(
    eventNumbers.map(num => {
      return new Promise((resolve, reject) => {
        const detailInfo = getShopbyItemExhibitionDetail({
          eventNo: num,
          order: 'ADMIN_SETTING',
          soldout: true,
        });
        resolve(detailInfo);
      });
    }),
  );

  return res;
};

export const getShopbyBestReviews = async (
  params: IShopbyBestRevewRequestPayload,
) => {
  const { boardType, pageNumber } = params;

  let queryParams = `boardType=${boardType}&pageNumber=${pageNumber}`;

  for (const [key, value] of Object.entries(params)) {
    if (key === 'isWidget') {
      queryParams += `&isWidget=${value}`;
    } else if (key === 'sortingSortCriterion') {
      queryParams += `&sorting.sortCriterion=${value}`;
    } else if (key === 'sortingOrdering') {
      queryParams += `&sorting.ordering=${value}`;
    }
  }

  const res = await shopbyBaseApi.get(`/reviews/boards?${queryParams}`);
  return res.data;
};

export const getShopbyItemDetail = async (payload: {
  productNo: number;
  partnerName: string;
}) => {
  const res = await shopbyBaseApi.get(`/products/${payload.productNo}`);
  return res.data;
};

const productSearchApiPrefix = {
  discountedPrices: `filter`,
  keywords: `filter`,
  keywordInResult: `filter`,
  discountedComparison: `filter`,
  deliveryConditionType: `filter`,
  saleStatus: `filter`,
  soldout: `filter`,
  totalReviewCount: `filter`,
  familyMalls: `filter`,
  productManagementCd: `filter`,
  excludeMallProductNo: `filter`,
  includeMallProductNo: `filter`,
  propNos: `filter.customProperties`,
  propValueNos: `filter.customProperties`,
  by: `order`,
  direction: `order`,
  soldoutPlaceEnd: `order`,
};
const productSearchApiPrefixMap = new Map(
  Object.entries(productSearchApiPrefix),
);

/**
 * 참고: https://docs.shopby.co.kr/?url.primaryName=product/#/Product/get-products-search
 */
export const getShopbyProductSearch = async (
  params: IShopbyProductSearchRequest,
): Promise<IShopbyProductSearchResponse> => {
  let queryString = ``;
  for (const [key, value] of Object.entries(params)) {
    const isExist = productSearchApiPrefixMap.has(key);
    if (isExist) {
      const prefix = productSearchApiPrefixMap.get(key);
      queryString += `${prefix}.${key}=${value}&`;
    } else {
      queryString += `${key}=${value}&`;
    }
  }
  const removedAmperSand = queryString.slice(0, -1);
  const res = await shopbyBaseApi.get(`/products/search?${removedAmperSand}`);

  return res.data;
};

export const getShopbyCategoryInfo = async () => {
  const res = await shopbyBaseApi.get(`/categories`);

  return res.data;
};
