import { getServerPhase } from 'utils/common';

export const getShopbyClientId = () => {
  const serverPhase = getServerPhase();

  if (
    serverPhase === 'production' ||
    serverPhase === 'staging' ||
    serverPhase === 'qa'
  ) {
    return process.env.REACT_APP_SHOPBY_CLIENT_ID;
  }

  return process.env.REACT_APP_SHOPBY_CLIENT_ID_DEV;
};

const getShopbyENV = {
  getShopbyClientId,
};

export default getShopbyENV;
