import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ICheckSecretExhibitionResponse,
  IShopbyItemExhibitionContentsInfo,
  IShopbyItemExhibitionDetailInfo,
  ITShopbyErrorRes,
} from '@types';
import {
  IShopbyExhibitionDetailRequestPayload,
  IShopbyExhibitionRequestPayload,
  IShopbyExhibitionResponsePayload,
  IShopbyItemExhibitionState,
} from 'store/types';

const initialState: IShopbyItemExhibitionState = {
  // 스토어 main 태그 기획전 / 기획전 상세페이지에서 탭 으로 조회되는 기획전 목록
  publicExhibition: {
    totalCount: 0,
    exhibitionList: [] as IShopbyItemExhibitionContentsInfo[],
    paging: {
      pageNumber: 1,
      pageSize: 10,
      hasMore: true,
    },
    shopbyError: undefined,

    // 스토어 pbTime 태그 기획전 / 타임특가 기획전
    specialPriceExhibition: {
      eventNumber: null,
      data: null,
    },
  },
  privateExhibition: {
    eventNumbers: null,
    prSpecial: null,
    prGift: null,
    privateExhibitionItemMap: new Map(),
    shopbyError: undefined,
  },
  activeTabKey: '',
  exhibitionDetailList: [],

  // 스토어 main tag 기획전 / 메인 노출되는 기획전 (타임특가 다음 순서로)
  mainTagExhibitions: {
    eventNumbers: [],
    exhibitionList: [],
  },
  // 시크릿 기획전 여부
  exhibitionVisibleInfo: {},
};

export const shopbyItemExhibitionSlice = createSlice({
  name: 'shopbyItemExhibition',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      state.activeTabKey = key;
    },

    getShopbyItemExhibitionRequest: (
      state,
      action: PayloadAction<IShopbyExhibitionRequestPayload>,
    ) => {
      state.publicExhibition.totalCount = 0;
      state.publicExhibition.exhibitionList = [];
      state.publicExhibition.paging = {
        pageNumber: 1,
        pageSize: 10,
        hasMore: true,
      };
      state.publicExhibition.shopbyError = undefined;
    },
    getShopbyItemExhibitionSuccess: (
      state,
      action: PayloadAction<{
        data: IShopbyExhibitionResponsePayload;
      }>,
    ) => {
      const data = action.payload.data;

      state.publicExhibition.totalCount = data.totalCount;
      if (state.publicExhibition.exhibitionList) {
        state.publicExhibition.exhibitionList.push(...data.contents);
      } else {
        state.publicExhibition.exhibitionList = data.contents;
      }
    },
    getShopbyItemExhibitionFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.publicExhibition.shopbyError = action.payload;
    },
    getShopbyItemExhibitionDetailRequest: (
      state,
      action: PayloadAction<IShopbyExhibitionDetailRequestPayload>,
    ) => {},
    getShopbyItemExhibitionDetailSuccess: (
      state,
      action: PayloadAction<{
        data: IShopbyItemExhibitionDetailInfo;
      }>,
    ) => {
      const data = action.payload.data;
      const eventNoString = data.eventNo.toString();

      const index = state.exhibitionDetailList.findIndex(
        detail => detail.key === eventNoString,
      );

      if (index < 0) {
        state.exhibitionDetailList.push({
          key: eventNoString,
          detail: data,
        });
      }
    },
    getShopbyItemExhibitionDetailFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.publicExhibition.shopbyError = action.payload;
    },

    // 숨김 기획전 조회하기 req.
    getShopbyPrivateExhibitionRequest: (
      state,
      action: PayloadAction<IShopbyExhibitionRequestPayload>,
    ) => {},

    getShopbyPrivateExhibitionSuccess: (
      state,
      action: PayloadAction<IShopbyExhibitionResponsePayload>,
    ) => {
      state.privateExhibition.eventNumbers = action.payload.contents.map(
        content => content.eventNo,
      );
    },
    getShopbyPrivateExhibitionFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.publicExhibition.shopbyError = action.payload;
    },

    // 숨김 기획전 상세 req.
    getShopbyPrivateExhibitionDetailRequest: (
      state,
      action: PayloadAction<number[]>,
    ) => {},

    getShopbyPrivateExhibitionDetailSuccess: (
      state,
      action: PayloadAction<IShopbyItemExhibitionDetailInfo[]>,
    ) => {
      const details = action.payload;
      for (const privateExhibition of details) {
        const tag = privateExhibition.tag as 'prSpecial' | 'prGift';
        state.privateExhibition[tag] = privateExhibition;
      }
      const mapping = new Map<number, string[]>();

      details.forEach(exhibition => {
        const tag = exhibition.tag;
        const products = exhibition.section[0].products;

        products.forEach(product => {
          const productNo = product.productNo;
          const mapValue = mapping.get(productNo) || [];
          mapValue.push(tag);
          mapping.set(productNo, mapValue);
        });
      });
      state.privateExhibition.privateExhibitionItemMap = mapping;
    },

    getShopbyPrivateExhibitionDetailFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.privateExhibition.shopbyError = action.payload;
    },

    // 타임특가 기획전 eventNo 요청.
    getShopbySpecialPriceExhibitionRequest: (
      state,
      action: PayloadAction<IShopbyExhibitionRequestPayload>,
    ) => {},

    getShopbySpecialPriceExhibitionSuccess: (
      state,
      action: PayloadAction<IShopbyExhibitionResponsePayload>,
    ) => {
      state.publicExhibition.specialPriceExhibition.eventNumber =
        action.payload.contents[0].eventNo;
    },
    getShopbySpecialPriceExhibitionFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.publicExhibition.shopbyError = action.payload;
    },

    // 타임특가 기획전 상세 요청.
    getShopbySpecialPriceExhibitionDetailRequest: (
      state,
      action: PayloadAction<IShopbyExhibitionDetailRequestPayload>,
    ) => {},

    getShopbySpecialPriceExhibitionDetailSuccess: (
      state,
      action: PayloadAction<IShopbyItemExhibitionDetailInfo>,
    ) => {
      state.publicExhibition.specialPriceExhibition.data = action.payload;
    },
    getShopbySpecialPriceExhibitionDetailFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.publicExhibition.shopbyError = action.payload;
    },

    // main tag 기획전 요청.
    getShopbyMainTagExhibitionRequest: (state, action) => {},
    getShopbyMainTagExhibitionSuccess: (
      state,
      action: PayloadAction<IShopbyExhibitionResponsePayload>,
    ) => {
      const mainTagExhibitions = action.payload.contents.filter(exhibition =>
        exhibition.tag.includes(`main`),
      );

      state.mainTagExhibitions.eventNumbers = mainTagExhibitions.map(
        exhibition => exhibition.eventNo,
      );
    },

    getShopbyMainTagExhibitionFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {},

    // main tag 기획전 상세 요청.
    getShopbyMainTagExhibitionDetailRequest: (state, action) => {},
    getShopbyMainTagExhibitionDetailSuccess: (
      state,
      action: PayloadAction<IShopbyItemExhibitionDetailInfo[]>,
    ) => {
      state.mainTagExhibitions.exhibitionList = action.payload;
    },

    getShopbyMainTagExhibitionDetailFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {},
    checkIsExhibitionVisibleRequest: (
      state,
      action: PayloadAction<string>,
    ) => {},
    checkIsExhibitionVisibleSuccess: (
      state,
      action: PayloadAction<ICheckSecretExhibitionResponse>,
    ) => {
      state.exhibitionVisibleInfo[action.payload.eventNo] =
        action.payload.data.confirmed;
    },
    checkIsExhibitionVisibleFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {},
    resetExhibitoinVisibleInfo: state => {
      state.exhibitionVisibleInfo = {};
    },
    resetExhibitionDetailList: state => {
      state.exhibitionDetailList = [];
    },
  },
});

export const {
  setActiveTab,

  getShopbyItemExhibitionRequest,
  getShopbyItemExhibitionSuccess,
  getShopbyItemExhibitionFailure,

  getShopbyItemExhibitionDetailRequest,
  getShopbyItemExhibitionDetailSuccess,
  getShopbyItemExhibitionDetailFailure,

  // 숨김 기획전 조회하기 요청.
  getShopbyPrivateExhibitionRequest,
  getShopbyPrivateExhibitionSuccess,
  getShopbyPrivateExhibitionFailure,

  // 숨김 기획전 상세 요청.
  getShopbyPrivateExhibitionDetailRequest,
  getShopbyPrivateExhibitionDetailSuccess,
  getShopbyPrivateExhibitionDetailFailure,

  // 타임특가 기획전 요청.
  getShopbySpecialPriceExhibitionRequest,
  getShopbySpecialPriceExhibitionSuccess,
  getShopbySpecialPriceExhibitionFailure,

  // 타임특가 기획전 상세 요청.
  getShopbySpecialPriceExhibitionDetailRequest,
  getShopbySpecialPriceExhibitionDetailSuccess,
  getShopbySpecialPriceExhibitionDetailFailure,

  // main tag 기획전 요청.
  getShopbyMainTagExhibitionRequest,
  getShopbyMainTagExhibitionSuccess,
  getShopbyMainTagExhibitionFailure,

  // main tag 기획전 상세 요청.
  getShopbyMainTagExhibitionDetailRequest,
  getShopbyMainTagExhibitionDetailSuccess,
  getShopbyMainTagExhibitionDetailFailure,
  // 시크릿 기획전 여부 조회
  checkIsExhibitionVisibleRequest,
  checkIsExhibitionVisibleSuccess,
  checkIsExhibitionVisibleFailure,
  resetExhibitoinVisibleInfo,
  resetExhibitionDetailList,
} = shopbyItemExhibitionSlice.actions;

export default shopbyItemExhibitionSlice.reducer;
