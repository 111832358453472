import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampSearchAutoCompleteData } from '@types';
import { isFuture, isToday } from 'date-fns';
import {
  ISearchFilterResponsePayload,
  ISearchFilterState,
  TOpenSectionName,
  TSearchFilterSelect,
} from 'store/types';

const initialState: ISearchFilterState = {
  locations: [],
  popularLocations: [],
  holidays: [],
  filters: [],
  autoCompleteCamps: [],
  activeSearchModalSection: 'filter',
  error: null,
};

export const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    getSearchFilterInfoRequest: (
      state,
      action: PayloadAction<TSearchFilterSelect | undefined>,
    ) => {
      state.error = null;
    },
    getSearchFilterInfoSuccess: (
      state,
      action: PayloadAction<ISearchFilterResponsePayload>,
    ) => {
      const { locations, popularLocations, filters, holidays } =
        action.payload.data;

      if (locations.length !== 0) {
        state.locations = locations;
      }

      if (popularLocations.length !== 0) {
        state.popularLocations = popularLocations;
      }

      if (filters && filters.length !== 0) {
        // NOTE. 누구와 필터 추가 전까지 옵셔널 처리
        state.filters = filters;
      }

      if (holidays.length !== 0) {
        const thisYear = new Date().getFullYear();
        const getYear = (month: number, day: number) => {
          return isFuture(new Date(thisYear, month - 1, day)) ||
            isToday(new Date(thisYear, month - 1, day))
            ? thisYear
            : thisYear + 1;
        };

        const convertedHolidays = holidays.map(h => {
          const year = h.isEveryYear ? getYear(h.month, h.day) : h.year;

          return {
            year,
            month: h.month,
            day: h.day,
            isEveryYear: h.isEveryYear,
            name: h.name,
          };
        });

        state.holidays = convertedHolidays;
      }

      state.error = null;
    },
    getSearchFilterInfoFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    campSearchAutoCompleteRequest: (state, action: PayloadAction<string>) => {
      state.error = null;
    },
    campSearchAutoCompleteSuccess: (
      state,
      action: PayloadAction<ICampSearchAutoCompleteData[]>,
    ) => {
      state.autoCompleteCamps = action.payload;
      state.error = null;
    },
    campSearchAutoCompleteFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.autoCompleteCamps = [];
      state.error = action.payload;
    },
    resetCampSearchAutoComplete: state => {
      state.autoCompleteCamps = [];
    },

    updateActiveSearchModalSection: (
      state,
      action: PayloadAction<TOpenSectionName>,
    ) => {
      state.activeSearchModalSection = action.payload;
    },
  },
});

export const {
  getSearchFilterInfoRequest,
  getSearchFilterInfoSuccess,
  getSearchFilterInfoFailure,

  campSearchAutoCompleteRequest,
  campSearchAutoCompleteSuccess,
  campSearchAutoCompleteFailure,
  resetCampSearchAutoComplete,

  updateActiveSearchModalSection,
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
