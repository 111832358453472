import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorModalComponent } from 'ErrorModalComponent';
import { ToastOptions } from 'react-toastify';
import { CellMeasurerCache } from 'react-virtualized/dist/es/CellMeasurer';
import { CommonState } from 'store/types';

const initialState: CommonState = {
  toast: undefined,
  errorModal: undefined,
  cellMeasurerCacheMap: new Map(),
  virtualizedScrollInfo: new Map(),
  headerVisibleInfo: {},
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    openErrorModal: (
      state,
      action: PayloadAction<IErrorModalComponent['errorModalContent']>,
    ) => {
      state.errorModal = action.payload;
    },
    closeErrorModal: state => {
      state.errorModal = undefined;
    },
    setToastContent: (
      state,
      action: PayloadAction<
        { id?: string; message: string; option?: ToastOptions<{}> } | undefined
      >,
    ) => {
      state.toast = action.payload;
    },
    setCellCache: (
      state,
      action: PayloadAction<Map<string, CellMeasurerCache>>,
    ) => {
      state.cellMeasurerCacheMap = action.payload;
    },
    updateCellCache: (
      state,
      action: PayloadAction<{ id: string; cache: CellMeasurerCache }>,
    ) => {
      const { id, cache } = action.payload;
      state.cellMeasurerCacheMap.set(id, cache);
    },
    resetCellCache: state => {
      state.cellMeasurerCacheMap = new Map();
    },
    updateVirtualizedScrollInfo: (
      state,
      action: PayloadAction<{
        id: string;
        scrollInfo: {
          scrollTop: number;
          windowScrollY: number;
        };
      }>,
    ) => {
      const { id, scrollInfo } = action.payload;
      state.virtualizedScrollInfo.set(id, scrollInfo);
    },
    setVirtualizedScrollInfo: (
      state,
      action: PayloadAction<
        Map<
          string,
          {
            scrollTop: number;
            windowScrollY: number;
          }
        >
      >,
    ) => {
      state.virtualizedScrollInfo = action.payload;
    },
    resetVirtualizedScrollInfo: state => {
      state.cellMeasurerCacheMap = new Map();
    },
    setHeaderVisibleInfo: (
      state,
      action: PayloadAction<{ [key: string]: boolean }>,
    ) => {
      state.headerVisibleInfo = action.payload;
    },
    updateIsHeaderVisible: (
      state,
      action: PayloadAction<{ id: string; value: boolean }>,
    ) => {
      const { id, value } = action.payload;
      state.headerVisibleInfo[id] = value;
    },
    resetHeaderVisibleInfo: state => {
      state.headerVisibleInfo = {};
    },
  },
});

export const {
  openErrorModal,
  closeErrorModal,
  setToastContent,
  setCellCache,
  updateCellCache,
  resetCellCache,
  updateVirtualizedScrollInfo,
  setVirtualizedScrollInfo,
  resetVirtualizedScrollInfo,
  setHeaderVisibleInfo,
  updateIsHeaderVisible,
  resetHeaderVisibleInfo,
} = commonSlice.actions;

export default commonSlice.reducer;
